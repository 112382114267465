import React from "react"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"
import Newsletter from "components/Newsletter"

import { ContentSearch, Knowledge } from "page_components/wallpapers"

import ConfigProvider from "context/config"
import SearcherProvider from "context/searcher"
import { useProducts } from "context/products"

const SearchResultsContent = ({ location }) => {
  const { products } = useProducts()

  const formatted_products = products?.filter(item =>
    item?.productCategories?.nodes?.some(item => item?.slug === "tapety")
  )

  return (
    <ConfigProvider location={location} products={formatted_products}>
      <SearcherProvider>
        <ContentSearch />
      </SearcherProvider>
    </ConfigProvider>
  )
}

const SearchResults = ({ location }) => {
  return (
    <Layout location={location}>
      <Breadcrumbs title="Wyniki wyszukiwania" />
      <PageHeader title="Wyniki wyszukiwania" />
      <SearchResultsContent location={location} />
      <Knowledge />
      <Newsletter />
    </Layout>
  )
}

export const Head = () => <Seo title="Wyniki wyszukiwania" />

export default SearchResults
